
/* ==================Header======================================================================================================================== */
.Header-Add-new-order-btn{
    border-radius: 30px;
}
.Header-Main-wrapper {
    /* box-shadow: 0px 16px 14px 5px lightgrey; */
    box-shadow: 0 0 80px rgba(0,0,0,0.1);
    padding: 14px 65px;
}
.HeaderMain-logo-image {
    width: 55%;
}
button.Header-Add-new-order-btn {
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 6px 20px !important;
    background-color: rgb(1,115,212) !important;
    color: #ffffff !important;
    border-radius: 20px !important;
}
/* ==================End======================================================================================================================== */
/* ==================Login======================================================================================================================== */
.Login_page-wholeMain-wrapper::before {
    background-image: url('../../public/assets/images/base.png');
    background-position: -118px 40px;
    background-repeat: no-repeat;
    background-size: 300px;
    content: '';
    width: 300px;
    height: 300px;
    position: absolute;
    opacity: 0.1;
}


.Login_page-wholeMain-wrapper::after{
    background-image: url('../../public/assets/images/base.png');
    background-position: right -118px bottom;
    background-repeat:  no-repeat;
    background-size:  300px;
    content:'';
    width: 300px;
    height: 300px;
    position: absolute;
    opacity: 0.1;
    right:0px;
    bottom:40px;
 }
.Login-page-form {
    width: 30%;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 4px 33px 12px #d6d6d6;
    padding: 25px;
    padding-top: 37px;
    border-radius: 8px;
    text-align: center;
}
.LoginPage-Main-wrapper {
    height:100vh;
    align-items: center;
    align-self: center;
    vertical-align: middle;
    background-color: white;
    display: flex;
    width:100%;
}
.Login-page-form .css-1a1fmpi-MuiInputBase-root-MuiInput-root{
    width:95%;
    margin:auto;
}
.Login-page-form-logo-image{
    width:60%;
}
.Loginpage-main-form-inner-div{
margin-top:25px !important;
}
.Login-form-p {
    margin: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    color: rgb(1,115,212);
    font-weight: 600;
    margin-top:10px  !important;
    margin-bottom:0px !important; 
}
.Login-page-form h6 {
    margin: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 400;
    font-size: 17px;
    margin-top: 0px !important;
}
.Loginpage\=-main-form {
    margin: 24px;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 13px;
    margin-top: 46px;
}
.Login-page-form .css-1x51dt5-MuiInputBase-input-MuiInput-input{
    font-size: 19px !important;
    font-weight: 600!important;
    color: #000000 !important;
    letter-spacing: 0px !important;
}
.Login-page-form .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 15.9px;
    font-family: 'Poppins', sans-serif;
    color:black;
    font-weight: 500;
}
.css-1a1fmpi-MuiInputBase-root-MuiInput-root:before{
    border-bottom: 1px solid rgb(252 240 240 / 42%);
}
.Loginpage-btn-wrapper-btn {
 
}
.Loginpage-btn-wrapper-btn{
    color: #ffffff !important;
    text-transform: capitalize !important;
    font-family: 'Poppins', sans-serif !important;
    font-size:20px !important;
    background-color: rgb(1,115,212) !important;
    padding: 10px !important;
    border-radius: 7px !important;
    width: 100% !important;
    border:none;
}
.Login-page-form .css-1d1r5q-MuiFormHelperText-root.Mui-error{
font-weight: 400;
font-size: 10px;
padding-left:39px;
}
.Login-page-form .css-dmmspl-MuiFormGroup-root{
    padding-left: 15px
}
img.Logout-image {
    width: 100%;
    margin-left: 2px;
}
/* ==================End======================================================================================================================== */
/* ==================Dashboard======================================================================================================================== */

.Dashboard-WholeMain-wrapper{
    /* margin-top:5rem; */
    margin-top: 1rem;
}
.col-md-4.text-center.Dashboard-content-div-one-left-side {
    /* width: 30% !important; */
    padding: 0px;
    /* margin:10px; */
}
.col-md-4.text-left.HeaderMain-logo-image-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Dashboard-content-div-one-left-side-h3 {
    color: rgb(1,115,212);
    text-align: left !important;
    font-weight: 700;
    font-size: 18px;
    padding: 15px 25px;
    margin-bottom: 0px;
}
.Dashboard-content-div-one-left-side-inner-one h5 {
    font-weight: bold;
    font-size: 15px;
    margin-bottom:0px;
}
.Dashboard-content-div-one-left-side-inner-one p {
    font-size: 13px;
}
.d-flexx-center.Dashboard-content-inner-wrapper-one {
    border-top: 1px solid rgb(209 206 206);
    padding: 15px 26px;
}
.Dashboard-content-div-one-left-side-inner-two{
    text-align: end !important;
}
/* .Dashboard-content-div-one-left-side-inner-two > svg {
    display: none;
} */
.rotate-90{
    transform:rotate(90deg);
    margin-right:10px;
    margin-left:10px;
}
.play-icon{
    margin-right:10px;
    margin-left:10px;  
}
.DashboardPage-Orders-list-wrapper {
    padding: 0px 0px;
    margin: 1rem 0;
}
.DashboardPage-Orders-list-wrapper-div-one .css-dmmspl-MuiFormGroup-root{
    flex-direction: row !important;
    display: none;
}
.DashboardPage-Orders-list-wrapper-div-one .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label{
font-weight:bold;
font-family: 'Poppins', sans-serif;
}
.DashboardPage-Orders-list-wrapper ul.MuiPagination-ul.css-wjh20t-MuiPagination-ul {
    justify-content: end;
    margin: 10px;
    margin-right: 0px;
  }
  .Map-Main-content-heading-switch{
  display: flex;
  align-items: center;
  justify-content: space-between;
  }
  .MuiFormControlLabel-label{
    color: rgb(1,115,212);
    text-align: left !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    padding: 15px 25px !important;
    margin-bottom: 0px !important;
    padding-left:0px !important;
  }  
table.OrderList-table-wrapper-Main {
    width: 100%;
    border-collapse: collapse;
}
table.OrderList-table-wrapper-Main thead{
    background-color: rgb(224,238,249);
    color:black;
}
table.OrderList-table-wrapper-Main thead tr th{
    padding:10px 10px;
    font-size: 14px;
}
table.OrderList-table-wrapper-Main thead tr th {
    padding: 15px 24px;
    font-weight: 500;
    font-size: 14px;
}
table.OrderList-table-wrapper-Main tbody tr td {
    padding: 15px 23px;
    border-bottom: 2px solid #efefef;
    font-size: 14px;
}
table.OrderList-table-wrapper-Main tbody tr h5 {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 700;
}
table.OrderList-table-wrapper-Main tbody tr td h6{
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 700;
}
table.OrderList-table-wrapper-Main tbody tr td p {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 14px;
}
.button-action button {
    text-transform: capitalize !important;
    color: #ffffff !important;
    background-color: rgb(88,88,88) !important;
    border-radius: 20px !important;
    padding: 5px 14px !important;
    font-size: 14px !important;
    margin: 5px !important;
    font-family: 'Poppins' !important;
}
.Pagination-wrapper-Main-Whole {
    justify-content: end;
    display: flex;
}
/* ==================End======================================================================================================================== */
/* ==================CancelOrder======================================================================================================================== */
.Modal-button-Main-btn{
border-radius: 30px !important;
border:1px solid rgb(205,4,2) !important;
color:rgb(205,4,2) !important;
padding:6px 41px !important;
font-weight: 600 !important;
margin: 10px !important;
margin-top: 0px !important;
}
.yes-red-active{
    background: linear-gradient(to right, #F4463D, rgb(205,4,2)) !important;
    color:#ffffff !important;
}

.Cancel-order-Modal-Mainwrapper,.Open-Cabin-Modal-Mainwrapper {
    text-align: center !important;

}
.Cancel-order-Modal-Mainwrapper h3 {
    font-size: 22px;
    font-weight: 700;
    color:rgb(205,4,2) !important;
}
.Modal-button-Main-wrapper {
    margin-top: 29px !important;
}
.Modal-button-Main-btn-two{
    border: 1px solid rgb(1 115 212) !important;
    color:rgb(1 115 212) !important;
    border-radius: 30px !important;
    padding:6px 41px !important;
    font-weight: 600 !important;
    margin: 10px !important;
    margin-top: 0px !important;
}
.yes-blue-active{
    background-color: rgb(1 115 212) !important;
    color:#ffffff !important;
  }
.Open-Cabin-Modal-Mainwrapper h3{
 color:rgb(1 115 212);
 font-size: 22px;
 font-weight: 700;
}
/* ==================End======================================================================================================================== */

/* ===================Add New Order======================================================================================================================================== */

.AddNew-order-Modal-div-one h3 {
    color: rgb(1 115 212);
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0px;
}
.AddNew-order-Modal-Mainwrapper h4{
 font-size:16px;
 font-weight:700;
}
.AddNew-order-Modal-div-two-inner-one input {
    border-radius: 30px;
    border: 1px solid rgb(209 206 206);
    height: 15.9px;
    margin: 5px;
    margin-left: 0px;
    width: 261px;
    padding: 13px !important;
}
.AddNew-order-Modal-Mainwrapper .css-1a1fmpi-MuiInputBase-root-MuiInput-root::before{
    border:none !important;
}
.AddNew-order-Modal-Mainwrapper .css-1a1fmpi-MuiInputBase-root-MuiInput-root::after{
    border:none !important;
}
.MuiBottomNavigation-root.css-de1c49{
    flex-wrap: wrap !important;
}
.AddNew-order-Modal-div-one {
    padding: 10px 23px;
    padding-top: 0px;
    border-bottom: 1px solid rgb(209 206 206);
}
.AddNew-order-Modal-div-two,.AddNew-order-Modal-div-three {
    padding: 10px 23px;
}
.AddNew-order-Modal-div-two-inner-two{
    width:60%;
}
.BottomNavigate-btn{
    border-radius: 30px !important;
    border:1px solid rgb(209 206 206) !important;
    min-height: 10px;
    height: 49px;
    margin:10px !important;
    font-size: 15px !important;
    font-weight: 700;
    max-width: unset !important;
    opacity: 1 !important;
    display: -webkit-inline-box !important;
    min-width:unset !important;  
    padding: 0px 28px !important;
    max-height:30px !important;
    height:100% !important;
}
.AddNew-order-Modal-Mainwrapper .css-1gh6k7f-MuiBottomNavigationAction-label{
    opacity: 1 !important;  
}
.AddNew-order-Modal-Mainwrapper .css-imwso6-MuiBottomNavigationAction-label{
    font-size: 13px !important;
}
.AddNew-order-Modal-Mainwrapper .css-16lloyr-MuiBottomNavigation-root{
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    display: block !important;
}
.AddNew-order-Modal-Mainwrapper .Mui-selected {
    font-weight: 700;
    text-transform: capitalize !important;
    background-color: rgb(233,233,233) !important;
    font-size: 15px !important;
    color: rgb(1 115 212) !important;
}
.AddNewOrder-page-Submit0btn-wrapper{
    text-align: end;
    padding: 10px 23px;
    margin-top:70px;
}
.AddNewOrder-page-Submit0btn-wrapper button{
    background:linear-gradient(to right,rgb(31,140,233), rgb(2,111,206));
    color:white;
    padding: 9px 46px;
    border-radius: 30px;
    text-transform: capitalize !important;  
    font-family: 'Poppins', sans-serif;
    font-size:16px !important;
}
.location-round-image{
    width: 100px;
    height:40px;
    margin:10px;
}
.BottomNavigation-Wrapper{
    flex-wrap: wrap !important;
    display: flex !important;
    justify-content: flex-start !important;
}
/* ==================End======================================================================================================================== */


/* ==================Map======================================================================================================================== */
.mapouter{
    position:relative;
    text-align:right;
    width:100%;
    height:200px;
}
/* .Map-Main-Whole-wrapper{
    height:300px;
    overflow-y: scroll !important;
} */
.Map-Main-Whole-wrapper figure{
    height:300px;
    overflow-y: scroll !important;
    border-radius: 0 0 10px 10px;
    margin: 0;
}
.Map-Main-Whole-wrapper svg{
    /* margin-top:-100px; */
    /* width: 100% !important; */
    border-radius: 0 0 10px 10px;
}
.gmap_canvas {
    overflow:hidden;
    background:none!important;
    width:100%;
    height:200px;
}
.gmap_iframe {
    width:100%!important;
    height:200px!important;
    border-radius: 0 0 10px 10px;
}
.dashboard-right-side-div{
    /* width:66% !important;
    margin:10px; */
}
.curve-bg {
    background-image: url('../../public/assets/images/curve.svg');
    background-repeat: no-repeat;
    background-position: -44px 10px;
    background-size: 112%;
    position: relative;
    width: 100%;
}
.curve-bg p {
    background-color: #FFF;
    display: inline-block;
    padding-right: 15px;
    position: relative;
}
.curve-bg p:after {
    background: #dddddd;
    position: absolute;
    right: 0;
    top: 7px;
    width: 7px;
    height: 7px;
    content: ' ';
    border-radius: 100%;
}
.curve-bg p.drop:after {
    top: 7px;
    background-color: #000000;
}
.curve-bg p.drop.dropoff:after {
    background:#006ecc;
}
.curve-bg p.drop.cancel:after {
    background:#cc0000;
}
.curve-bg p.drop.delivered:after {
    background:#5cc942;
}
.leaflet-container {
    width: 100%;
    height: 35vh;
  }


  
/* ==================End======================================================================================================================== */

img.leaflet-image-layer.leaflet-zoom-animated.leaflet-interactive{
    object-fit: contain;
    /* width: 100% !important; 
     height:600px !important;   */
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom{
/* background: transparent !important; */
}
.disabled-link {
    pointer-events: none !important;
  }

/* ==================SelectrFleetPage=======================================================================================================================================================   */
.SelectFleetPage-content-wrapper-Main{
    display: flex;
    flex-wrap: wrap;
}
.SelectFleetPage-inner-li-div{
    width:400px;
    margin:7px;
    cursor: pointer;
    border-top: none !important;
}
.SelectFleetPage-content-wrapper-Main .Dashboard-content-div-one-left-side-h3{
    text-align: center !important;
    border-bottom: 3px solid #efefef;
    padding: 26px;
    font-size: 23px;
}
.SelectFleetPage-content-wrapper-Main .d-flexx-center {
  
    padding-right: 30px;
    padding-left: 30px;
}
.container.SelectFleetPage-wrapper-Main {
    margin-top: 3rem;
}
.container.SelectFleetPage-wrapper-Main .SelectPageMain-Heading {
    margin: 22px;
    margin-left: 0px;
}
.SelectFleet-Page-p-tag {
    font-size: 21px;
}
.SelectFleet-Page-h5-tag {
    font-weight: 800;
}
/* ==================End=======================================================================================================================================================   */
/* figure.zoom:hover{
background-size: 10000px !important;
} */


